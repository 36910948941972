/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import ScrollIndicator from './scrollIndicator';
import Menu from './menu';
import Footer from './footer';
import './layout.scss';

const Layout = ({ children }) => (
  <div id="app">
    <Menu />
    <div id="content-pane">
      <ScrollIndicator />

      <main>
        <div id="content">
          {children}
        </div>
      </main>

      <Footer />
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
